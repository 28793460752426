import React, { useState, useEffect, useRef } from 'react'
import Subtitle from './Subtitle.jsx'
import 'react-image-shadow/assets/index.css'
import { ColorExtractor } from 'react-color-extractor'

const Concert = (props) => {
	const concertContainerStyles =
		'md:flex md:flex-row-reverse md:mx-auto md:justify-center md:gap-20 md:items-center md:py-28'
	const textContainerStyles = 'pb-12 md:w-96'

	const [imageColor, setImageColor] = useState('')
	const concertRef = useRef(null)

	useEffect(() => {
		const currentConcertRef = concertRef.current

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						props.setRepertoireBgColor(imageColor)
					}
				})
			},
			{
				threshold: 0.5,
			}
		)

		if (currentConcertRef) {
			observer.observe(currentConcertRef)
		}

		return () => {
			if (currentConcertRef) {
				observer.unobserve(currentConcertRef)
			}
		}
	}, [props, imageColor]) // Add imageColor as a dependency

	return (
		<div ref={concertRef} className={concertContainerStyles}>
			<div className={textContainerStyles}>
				<Subtitle level={2} text={props.title} />
				<div>{props.text}</div>
			</div>
				<div className='pb-12 max-w-full' style={{width: '500px', height: '500px'}}>
					<ColorExtractor getColors={(colors) => setImageColor(colors[2])}>
						<img src={props.image} alt={props.title} className='rounded-lx' style={{}} />
					</ColorExtractor>
				</div>
		</div>
	)
}

export default Concert
