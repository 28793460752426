import React from 'react'
import { createRoot } from 'react-dom/client'
import { hot } from 'react-hot-loader/root'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

const HotApp = hot(App)
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <HotApp />
  </BrowserRouter>,
)

if (module.hot) {
  module.hot.accept()
}
